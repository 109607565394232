import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'querystring'
import isEmpty from 'lodash/isEmpty'
import { useLoad, usePostRequest } from '../hooks/request'
import Layout from '../components/Layout/Layout'
import { CLIENT_LIST, SEND_MESSAGE_SELECT } from '../urls'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import Table from '../components/common/Table'
import ClientCreate from '../components/ClientCreate'
import { useMessage } from '../hooks/message'
import ArchiveTabs from '../components/ArchiveTabs'
import BorrowArchiveItem from '../components/BorrowArchiveItem'

export default function ArchiveBorrow() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const clients = useLoad({
        url: CLIENT_LIST,
        params: {
            page,
            ...params,
            type: 'archive',
        },
    }, [params, page])
    const productList = clients.response ? clients.response.results : []

    const [showCreateModal, setShowCreateModal] = useModal(
        <ClientCreate
            clients={clients}
            onCancel={() => setShowCreateModal()}
        />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({
            ...params,
            search,
        })}`)
    }

    const [checkedItems, setCheckedItems] = useState([])
    const handleCheckboxChange = (itemId, isChecked) => {
        setCheckedItems((prevState) => {
            if (isChecked) {
                return [...prevState, itemId]
            }
            return prevState.filter((id) => id !== itemId)
        })
    }
    const [showMessage] = useMessage()
    const send = usePostRequest({ url: SEND_MESSAGE_SELECT })

    async function onSend() {
        const { success } = await send.request({ data: { client_ids: checkedItems } })
        if (success) {
            showMessage('Сизнинг sms хабарингиз муваффақиятли юборилди', 'is-success')
            setCheckedItems([])
            clients.request()
        }
    }

    return (
        <Layout onSearch={onSearch} onSend={onSend} showCreateModal={showCreateModal}>
            <ArchiveTabs />

            <div>
                <div className="box">
                    <Table
                        loading={clients.loading}
                        items={productList}
                        columns={{
                            name: 'Имя',
                            address: 'Номер телефона',
                            price: 'Цена',
                            actisadons: 'Выберите',
                            actidssadons: 'Последней отправки',
                            action: '',
                        }}
                        totalCount={clients.response ? clients.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item) => (
                            <BorrowArchiveItem
                                onCheckboxChange={handleCheckboxChange}
                                clients={clients}
                                products={clients}
                                key={item.id}
                                item={item}
                            />
                        )} />
                </div>
            </div>
        </Layout>
    )
}
