import React from 'react'
import { usePutRequest } from '../hooks/request'
import { ORDER_DETAIL } from '../urls'
import CheckForm from './CheckForm'

export default function CheckUpdate({ onCancel, reload, item }) {
    const productUpdate = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await productUpdate.request({
            data: {
                ...data,
                items: data.orderProduct,
                client: data.client.id,
            },
        })
        if (success) {
            reload.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Проверка обновления</b></h1>

            <CheckForm
                initialValues={item}
                loading={productUpdate.loading}
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>
    )
}
