export const languages = {
    uz: 'O\'zbek',
    ru: 'Русский',
    en: 'English',
}

export const deliveryTypes = {
    delivery: 'Доставка',
    pickup: 'Самовывоз',
}
export const seasons = [
    { name: 'Зимний', value: 'winter' },
    { name: 'Летний', value: 'summer' },
]
export const classes = {
    admin: 'is-success',
    accountant: 'is-danger',
    marketing: 'is-primary',
    teacher: 'is-warning',
}

export function measurement(value) {
    const statuses = [
        { name: 'шт', value: 'pieces' },
        { name: 'литр', value: 'litres' },
        { name: 'блок', value: 'stock' },
        { name: 'кг', value: 'kg' },
    ].filter((elem) => elem.value === value)[0]

    return statuses.name
}

export function position(name) {
    return languages[name]
}
export function season(name) {
    return seasons[name]
}

export function deliveryPosition(name) {
    return deliveryTypes[name]
}

export function positionClass(name) {
    return classes[name]
}
