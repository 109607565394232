import React from 'react'
import Button from './common/Button'
import { BORROW_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import BorrowUpdate from './BorrowUpdate'
import { getDateOtherFormat, getDateTime } from '../utils/date'
import { format } from '../utils/number'
import { useCustomModal } from '../hooks/customModal'

export default function ClientBorrowItem({
    reload,
    item,
    borrows,
    index,
}) {
    const clientBorrowRemove = useDeleteRequest({ url: BORROW_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await clientBorrowRemove.request()
            borrows.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useCustomModal(
        <BorrowUpdate
            borrows={borrows}
            reload={reload}
            item={item}
            hideModal={() => hideUpdateModal()}
        />,
    )

    const currentDate = new Date()

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td className="is-size-5">{index + 1}</td>
            <td className="is-size-5">{format(item.amount)}</td>
            <td className="is-size-5">{item.description}</td>
            <td className="is-size-5">{getDateTime(item.createdAt)}</td>
            <td className="is-size-5">{item.termDate}</td>

            <td style={{ display: 'block' }} className="mt-2">
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 is-success"
                    icon="pencil-outline"
                />
                <Button
                    onClick={onDelete}
                    className="is-danger mr-2"
                    icon="trash"
                />
            </td>

        </tr>

    )
}
