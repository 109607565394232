import React, { useState } from 'react'

import queryString from 'querystring'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { CATEGORY_LIST } from '../urls'
import CategoryCreate from '../components/CategoryCreate'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import Table from '../components/common/Table'
import CategoryItem from '../components/CategoryItem'
import ArchiveTabs from '../components/ArchiveTabs'

export default function ArchiveCategories() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const categories = useLoad({
        url: CATEGORY_LIST,
        params: {
            ...params,
            page,
            status: 'archive',
        },
    }, [params, page])

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <CategoryCreate
            reload={categories}
            onCancel={() => setShowUpdateModal()} />,
    )
    const history = useHistory()

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }

    return (
        <Layout onSearch={onSearch} showCreateModal={showUpdateModal}>
            <ArchiveTabs />
            <div className="">

                <div className="box is-full">
                    <Table
                        loading={categories.loading}
                        items={categories.response ? categories.response.results : []}
                        columns={{
                            name: 'Название',
                            // productsCount: 'Название',
                            actions: '',
                        }}
                        totalCount={categories.response ? categories.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item) => (
                            <CategoryItem
                                categories={categories}
                                key={item.id}
                                item={item}

                            />
                        )} />
                </div>

            </div>

        </Layout>
    )
}
