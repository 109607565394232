import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function ArchiveTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith('/archive/orders') })}>
                    <NavLink to="/archive/orders">Чеки</NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith('/archive/products') })}>
                    <NavLink to="/archive/products">Продукты</NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith('/archive/categories') })}>
                    <NavLink to="/archive/categories">Категории</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/archive/clients') })}>
                    <NavLink to="/archive/clients">Клиенты</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/archive/borrows') })}>
                    <NavLink to="/archive/borrows">Долги</NavLink>
                </li>
            </ul>
        </div>
    )
}
