import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useLoad } from '../hooks/request'
import { BORROW_LIST } from '../urls'
import empty from '../static/empty-product.png'
import Table from './common/Table'
import { useQueryParams } from '../hooks/queryString'
import Button from './common/Button'
import ClientBorrowItemArchive from './ClientBorrowItemArchive'

export default function ClientBorrowListArchive({
    clientId,
    client,
    hideModal,
}) {
    const { params } = useQueryParams()
    const [page, setPage] = useState(1)
    const borrows = useLoad({
        url: BORROW_LIST,
        params: {
            page,
            ...params,
            client_id: clientId,
            status: 'archive',
        },
    }, [params, page])
    const borrowItem = borrows.response ? borrows.response.results : []

    return (
        <div className="">
            <div className="mb-2">
                <span className="is-size-3"><b>{client.name} - {client.phoneNumber}</b></span>
            </div>

            {borrows.response && borrows.response.count === 0 ? (

                <div>
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <h1><b>Не долг</b></h1>
                    </div>
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>

                </div>

            ) : (
                <div>
                    <Table
                        loading={borrows.loading}
                        items={borrowItem}
                        columns={{
                            pridasce: '',
                            price: 'Цена',
                            name: 'Описание',
                            registered_at: 'Создан в',
                            sactions: '',
                            actions: '',
                        }}
                        totalCount={borrows.response ? borrows.response.count : 0}
                        pageSize={5}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => (
                            <ClientBorrowItemArchive
                                index={index}
                                borrows={borrows}
                                clientId={clientId}
                                key={item.id}
                                item={item}

                            />
                        )} />
                </div>
            )}
            <div className="is-pulled-right mt-5">

                <Button
                    onClick={hideModal}
                    icon="close-outline"
                    text="Закрыть"
                    className="is-danger" />&nbsp;&nbsp;

            </div>

        </div>

    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        width: 800,
    },
})
