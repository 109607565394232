import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames' // Assuming you're using the 'classnames' library for the 'cn' function.

export default function BorrowTabs() {
    // const { path } = useRouteMatch()
    const location = useLocation()

    const getQueryParam = (param) => new URLSearchParams(location.search).get(param)

    const status = getQueryParam('status')

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': status === 'active' })}>
                    <NavLink to="/borrow?status=active">Все клиенты</NavLink>
                </li>
                <li className={cn({ 'is-active': status === 'weekly' })}>
                    <NavLink to="/borrow?status=weekly">Просроченные клиенты</NavLink>
                </li>
                <li className={cn({ 'is-active': status === 'monthly' })}>
                    <NavLink to="/borrow?status=monthly">Просроченные клиенты на 1 месяц</NavLink>
                </li>
            </ul>
        </div>
    )
}
