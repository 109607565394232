export const types = [{ value: 'outcome', label: 'Расход' }, { value: 'income', label: 'Приход' }]

export const measurements = [
    { name: 'шт', value: 'pieces' },
    { name: 'литр', value: 'litres' },
    { name: 'блок', value: 'stock' },
    { name: 'кг', value: 'kg' },
]

export function getMeasurement(value) {
    return measurements.find((item) => item.value === value).name
}

export const statuses = [
    { name: 'Новый', value: 'new' },
    { name: 'Принят', value: 'accepted' },
    { name: 'Доставлен', value: 'delivered' },
    { name: 'Отменён', value: 'canceled' },
]
