import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './common/Button'
import { CATEGORY_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import CategoryUpdate from './CategoryUpdate'
import { useModal } from '../hooks/modal'
import { maxLength } from '../utils/string'

export default function CategoryItem({ categories, item }) {
    const history = useHistory()
    const categoryRemove = useDeleteRequest({ url: CATEGORY_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await categoryRemove.request()
            categories.request()
        }
    }

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <CategoryUpdate
            reload={categories}
            item={item}
            hideModal={() => setShowUpdateModal()}
        />,
    )

    return (
        <tr className="is-capitalized" key={item.id}>
            <td className="has-text-link">
                <h3
                    className="is-size-5 mt-3 is-link"
                    onClick={() => history.push(`/home?category=${item.id}`)}>
                    {maxLength(item.name, 12)}
                </h3>
            </td>
            {/*    <td>
                100
            </td> */}

            <td className="has-text-right">
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success"
                    icon="pencil-outline" />
                <Button
                    onClick={onDelete}
                    className="button is-danger"
                    icon="trash" />

            </td>
        </tr>
    )
}
