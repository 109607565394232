import React, { Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import Layout from '../components/Layout/Layout'
import { useInfiniteScroll, useLoad } from '../hooks/request'
import { CATEGORY_DETAIL, CATEGORY_LIST, domain } from '../urls'
import CategoryCreate from '../components/CategoryCreate'
import { useModal } from '../hooks/modal'
import Loader from '../components/common/Loader'
import { useQueryParams } from '../hooks/queryString'
import CategoryDetailItem from '../components/CategoryDetailItem'

export default function CategoryDetail() {
    const params = useQueryParams()
    const categories = useInfiniteScroll({ url: CATEGORY_LIST, params }, [params])
    const category = useLoad({ url: CATEGORY_DETAIL.replace('{id}', params.category) })
    const user = JSON.parse(localStorage.user)

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <CategoryCreate
            reload={categories}
            category={category}
            onCancel={() => setShowUpdateModal()} />,
    )

    const history = useHistory()
    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }
    return (
        <Layout onSearch={onSearch} showCreateModal={showUpdateModal}>
            {!categories.loading ? (
                <div className="columns is-multiline">
                    {params.category ? (
                        <Fragment>
                            <div className="menu__content mt-5 ml-5 column is-2 has-text-centered button-active">
                                <img
                                    src={domain + (category.response ? category.response.icon : '')}
                                    alt="category"
                                    onClick={() => history.push(`/products?category=${params.category}`)}
                                    className="menu__img" />

                                <h3
                                    className="is-size-5 mt-3"
                                    onClick={() => history.push(`/products?category=${params.category}`)}>
                                    Все
                                </h3>
                            </div>
                        </Fragment>
                    ) : null}

                    {categories.response && categories.response.count !== 0 ? (
                        <Fragment>
                            <div className={css(styles.verticalLine)} />
                            {categories.response.results.filter((item) => (!params.category ? !item.parent : item))
                                .map((item) => (
                                    <CategoryDetailItem
                                        params={params}
                                        user={user}
                                        key={item.id}
                                        item={item}
                                        categories={categories} />
                                ))}
                            {categories.loading ? (
                                <Loader large center />
                            ) : (
                                <div ref={categories.ref} />
                            )}
                        </Fragment>
                    ) : null}
                </div>
            ) : (
                <Loader large center padded />
            )}
        </Layout>
    )
}

const styles = StyleSheet.create({
    emptyImg: {
        width: '300px',
        height: '300px',
    },
    verticalLine: {
        backgroundColor: '#ccc',
        width: 1,
        marginLeft: 20,
        height: 120,
        marginTop: 55,
    },
})
