import React from 'react'
import Button from './common/Button'
import { BORROW_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { getDateOtherFormat } from '../utils/date'
import { format } from '../utils/number'

export default function BorrowsItemArchive({
    item,
    borrows,
}) {
    const clientBorrowRemove = useDeleteRequest({ url: BORROW_DETAIL.replace('{id}', item.id) })
    console.log(item)
    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await clientBorrowRemove.request()
            borrows.request()
        }
    }

    const currentDate = new Date()

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td className="is-size-5">{item?.selectClient.name}</td>
            <td className="is-size-5">{item?.selectClient.phoneNumber}</td>
            <td className="is-size-5">{format(item.amount)} сум</td>

            <td style={{ display: 'block' }} className="mt-2">
                <Button
                    onClick={onDelete}
                    className="is-danger mr-2"
                    icon="trash"
                />
            </td>

        </tr>

    )
}
