import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { LanguageProvider } from './contexts/LanguageContext'
import Products from './pages/Products'
import Login from './pages/Login'
import ProtectedRoute from './components/common/ProtectedRoute'
import Borrow from './pages/Borrow'
import Categories from './pages/Categories'
import CategoryDetail from './pages/CategoryDetail'
import Home from './pages/Home'
import ArchiveProducts from './pages/ArchiveProducts'
import ArchiveCategories from './pages/ArchiveCategories'
import ArchiveBorrow from './pages/ArchiveBorrow'
import Archive from './pages/Archive'
import ArchiveBorrows from './pages/ArchiveBorrows'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
    }, [])

    return (
        <LanguageProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Login} exact />
                        <ProtectedRoute path="/home" component={Home} exact />
                        <ProtectedRoute path="/products" component={Products} exact />
                        <ProtectedRoute path="/categories" component={Categories} exact />
                        <ProtectedRoute path="/category" component={CategoryDetail} exact />
                        <ProtectedRoute path="/borrow" component={Borrow} exact />
                        <ProtectedRoute path="/archive/products" component={ArchiveProducts} exact />
                        <ProtectedRoute path="/archive/clients" component={ArchiveBorrow} exact />
                        <ProtectedRoute path="/archive/borrows" component={ArchiveBorrows} exact />
                        <ProtectedRoute path="/archive/categories" component={ArchiveCategories} exact />
                        <ProtectedRoute path="/archive/orders" component={Archive} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </LanguageProvider>
    )
}
