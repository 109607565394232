import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'querystring'
import cn from 'classnames'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { CATEGORY_LIST, PRODUCT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import CheckCreate from '../components/CheckCreate'
import ProductHomeItem from '../components/ProductHomeItem'
import Card from '../components/common/Card'
import { format } from '../utils/number'

export default function Home() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const products = useLoad({
        url: PRODUCT_LIST,
        params: {
            page,
            ...params,
            status: 'active',
        },
    }, [params, page])
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })

    const [showCreateModal, setShowCreateModal] = useModal(
        <ProductCreate
            reload={products}
            onCancel={() => setShowCreateModal()}
        />,
        styles.modal,
    )
    const [showCreateCheck, hideCreateCheck] = useModal(
        <CheckCreate
            reload={check}
            onCancel={() => hideCreateCheck()}
        />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({
            ...params,
            search,
        })}`)
    }

    const category = useLoad({
        url: CATEGORY_LIST,
        params: { status: 'active' },
    })
    const categoryItem = category.response ? category.response.results : []
    console.log(categoryItem)

    async function setUrl(key, value) {
        const val = params[key] !== String(value) ? value : undefined
        history.push(`?${queryString.stringify({
            ...params,
            [key]: val,
        })}`)
    }

    return (
        <Layout onSearch={onSearch} showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <div className="mb-2">
                <span onClick={() => {
                    setUrl('category', '')
                    setUrl('season', '')
                }} className={cn(`tag is-medium mr-2 mb-2 ${(params.category === '' && params.season === '')
                    ? 'is-danger' : 'is-link'}`)}>
                    Все
                </span>
                {categoryItem.map((item) => (
                    <span onClick={() => setUrl('category', item.id)}
                        className={cn(`tag is-medium mr-2 mb-2 ${params.category !== String(item.id) ? 'is-link' : 'is-danger'}`)}>
                        {item.name}
                    </span>
                ))}
            </div>
            <div className="mb-2">
                <span onClick={() => setUrl('season', 'winter')}
                    className={cn(`tag is-medium mr-2 mb-2 ${params.season === 'winter' ? 'is-success' : 'is-warning'}`)}>
                    Зимний
                </span>
                <span onClick={() => setUrl('season', 'summer')}
                    className={cn(`tag is-medium mr-2 mb-2 ${params.season === 'summer' ? 'is-success' : 'is-warning'}`)}>
                    Летний
                </span>
            </div>

            <div className="is-flex columns mt-2">
                <div className="column">
                    {products.response && products.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (
                        <div className="box">
                            <Table
                                loading={products.loading}
                                items={productList}
                                columns={{
                                    photo: 'Фото',
                                    name: 'Название',
                                    category: 'Категории',
                                    count: 'Количество',
                                    priceSale: 'Цена продажи',
                                    priceBuy: 'Цена покупки',
                                    measurement: 'Измерение',
                                    actions: '',
                                }}
                                totalCount={products.response ? products.response.count : 0}
                                pageSize={15}
                                activePage={page}
                                onPageChange={setPage}
                                renderItem={(item) => (
                                    <ProductHomeItem
                                        products={products}
                                        check={check}
                                        key={item.id}
                                        item={item}

                                    />
                                )} />
                        </div>
                    )}

                    <Card>
                        <b>
                            <div style={{ gap: 40 }} className="is-flex">
                                <p className="is-size-5">Общая сумма покупки:
                                    <span
                                        className="ml-2">{format(products.response
                                            ? products.response.totalSaleSum : 0)} сум
                                    </span>
                                </p>
                                <p className="is-size-5">Общая сумма продажи:
                                    <span className="ml-2">{format(products.response
                                        ? products.response.totalBuySum : 0)} сум
                                    </span>
                                </p>
                                <p className="is-size-5">Общая прибыль:
                                    <span className="ml-2">
                                        {
                                            format((products.response ? products.response.totalSaleSum : 0)
                                                - (products.response ? products.response.totalBuySum : 0))
                                        } сум
                                    </span>
                                </p>
                                <p className="is-size-5">Все продуктов количестве:
                                    <span className="ml-2">
                                        {
                                            (products.response ? products.response.totalProductsCount : 0)
                                        }
                                    </span>
                                </p>
                            </div>
                        </b>
                    </Card>
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        width: 1000,
    },
    checkmark: {
        marginRight: '3px',
    },

})
