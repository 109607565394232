import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import ViewCheckArchive from './ViewCheckArchive'
import { getDateTime } from '../utils/date'
import ComponentToPrint from './Print'
import { format } from '../utils/number'
import { usePutRequest } from '../hooks/request'
import { ORDER_DETAIL } from '../urls'

export default function ArchiveItem({
    item,
    ordersList,
}) {
    // const orderRemove = useDeleteRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })
    // eslint-disable-next-line max-len,no-return-assign
    const resultArchive = item.orderProduct.reduce((totalCost, currentValue) => {
        const count = parseInt(currentValue?.count, 10) || 0 // Assuming count is a property in your data structure
        const price = parseInt(currentValue?.price, 10) || 0 // Assuming price is a property in your data structure

        return totalCost + (count * price)
    }, 0)
    /*   async function onDelete() {
          if (global.confirm('Вы действительно хотите удалить?')) {
              await orderRemove.request()
              await ordersList.request()
          }
      } */

    const [showViewModal, setShowViewModal] = useModal(
        <ViewCheckArchive
            result={resultArchive}
            item={item}
            hideModal={() => setShowViewModal()}
        />,
    )
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const updateOrder = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await updateOrder.request({
            data: {
                ...data,
                status: 'active',
                items: item.orderProduct,
                client: item.client.id,
            },
        })
        if (success) {
            ordersList.request()
        }
    }

    return (
        <tr key={item.id}>
            <td onClick={showViewModal} className="is-size-5">{item.client.name}</td>
            <td className="is-size-5">{item.client.phoneNumber}</td>
            <td className="is-size-5">{format(resultArchive)}</td>
            <td className="is-size-5">{getDateTime(item.createdAt)}</td>
            <td className="has-text-right">
                <Button
                    onClick={handlePrint}
                    className="button is-link mr-2"
                    icon="print" />
                <div style={{ display: 'none' }}>
                    <ComponentToPrint result={item.totalForOrderHistories} item={item} ref={componentRef} />
                </div>
                <Button
                    onClick={() => onSubmit()}
                    className="button is-success"
                    icon="trash" />
            </td>
        </tr>
    )
}
