import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './common/Button'
import { CATEGORY_DETAIL, domain } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import CategoryUpdate from './CategoryUpdate'
import { useModal } from '../hooks/modal'
import { maxLength } from '../utils/string'

export default function CategoryDetailItem({ categories, item }) {
    const history = useHistory()
    const categoryRemove = useDeleteRequest({ url: CATEGORY_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await categoryRemove.request()
            categories.request()
        }
    }

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <CategoryUpdate
            reload={categories}
            item={item}
            hideModal={() => setShowUpdateModal()}
        />,
    )

    return (
        <div key={item.id} className="menu__content mt-5 ml-5 column is-2 has-text-centered button-active">
            <div className="is-flex" style={{ position: 'absolute', marginLeft: 46 }}>
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success button-default"
                    icon="pencil-outline" />

                <Button
                    onClick={onDelete}
                    className="button is-danger button-default"
                    icon="trash" />
            </div>

            <img
                src={domain + item.icon}
                alt={item.name}
                onClick={() => history.push(`/products?category=${item.id}`)}
                className="menu__img" />

            <h3
                className="is-size-5 mt-3"
                onClick={() => history.push(`/products?category=${item.id}`)}>
                {maxLength(item.name, 12)}
            </h3>
        </div>
    )
}
