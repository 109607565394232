import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import queryString from 'querystring'
import { DATE_AUTO_FORMAT } from '../../utils/date'
import { useQueryParams } from '../../hooks/queryString'
import Button from './Button'

export default function DateRange({
    startDate,
    endDate,
    onDatesChange,
}) {
    function onChange({ target }) {
        onDatesChange({
            startDate: moment(target.name === 'startDate' ? target.value : startDate).format(DATE_AUTO_FORMAT),
            endDate: moment(target.name === 'endDate' ? target.value : endDate).format(DATE_AUTO_FORMAT),
        })
    }

    const history = useHistory()
    const params = useQueryParams()
    const clearDates = () => {
        history.push(`?${queryString.stringify({
            ...params,
            startDate: '',
            endDate: '',
        })}`)
    }
    return (
        <div className={cn('columns is-mobile', css(styles.wrapper))}>
            <div className="column">
                <input
                    name="startDate"
                    type="date"
                    value={startDate}
                    className="input"
                    onChange={onChange} />
            </div>

            <span className="mt-4">
                &#x2192;
            </span>

            <div className="column">
                <input
                    name="endDate"
                    type="date"
                    value={endDate}
                    className="input"
                    onChange={onChange} />
            </div>
            <div className="column">
                <Button
                    onClick={clearDates}
                    className="button is-danger mr-2 is-outlined"
                    icon="trash" />
            </div>

        </div>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '10rem',
    },
    arrow: {
        // margin: '0.2rem 0.6rem 0.4rem 0',
        fontSize: '1.4em',
    },
})
