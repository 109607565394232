import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { CLIENT_LIST, ORDER_DETAIL } from '../urls'
import { useDeleteRequest, useLoad, usePutRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { format } from '../utils/number'
import CheckUpdate from './CheckUpdate'
import CheckProductItem from './CheckProductItem'
import CheckView from './CheckView'
import ComponentToPrint from './Print'
import Button from './common/Button'
import ClientUpdate from './ClientUpdate'

export default function CheckItem({
    reload,
    item,
    check,
    products,
}) {
    const checkRemove = useDeleteRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onDeleteCheck() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await checkRemove.request()
            await reload.request()
        }
    }

    const [showUpdateCheck, hideUpdateCheck] = useModal(
        <CheckUpdate
            item={item}
            reload={reload}
            onCancel={() => hideUpdateCheck()}
        />,
    )
    // eslint-disable-next-line max-len,no-return-assign
    const result = item.orderProduct.reduce((total, currentValue) => total + parseInt(currentValue?.product.priceSale * currentValue.count, 10), 0)

    const [showCheckView, hideCheckView] = useModal(
        <CheckView
            order={item}
            onCancel={() => hideCheckView()}
            item={item.orderProduct}
        />,
    )

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const updateOrder = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })
    async function onSubmit(data) {
        const { success } = await updateOrder.request({
            data: {
                ...data,
                status: 'archive',
                items: item.orderProduct,
                client: item.client.id,
            },
        })
        if (success) {
            reload.request()
        }
    }

    const clients = useLoad({ url: CLIENT_LIST })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <ClientUpdate
            check={check}
            clients={clients}
            item={item.client}
            onCancel={() => hideUpdateModal()}
        />,
    )
    return (

        <div className="card mb-4">
            <header className="card-header">
                <p className="card-header-title">
                    <span className="mr-1">{item.client?.name} - ({item.client?.phoneNumber})</span>

                    <Button
                        onClick={showUpdateModal}
                        className="button is-link is-pulled-center is-outlined mr-1 is-small"
                        icon="pencil" />
                </p>

                <div className="has-text-right is-flex m-2 is-small">
                    <Button
                        onClick={showUpdateCheck}
                        className="button is-success is-pulled-center is-outlined mr-1 is-small"
                        icon="pencil" />
                    <Button
                        onClick={onDeleteCheck}
                        className="button is-danger is-pulled-center is-outlined is-small"
                        icon="trash" />
                </div>
            </header>

            <div className="card-content">
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalized">
                    {item.orderProduct.map((element) => (
                        <CheckProductItem products={products} check={check} reload={reload} element={element} />
                    ))}
                    <td colSpan={7}>
                        <b>
                            <span className="is-flex is-justify-content-center">
                                <p className="is-size-5 pt-2 pb-2">Жами: {format(result)} СЎМ</p>
                            </span>
                        </b>
                    </td>
                </table>

            </div>

            <footer className="card-footer">
                <p onClick={showCheckView} className="card-footer-item is-bold">
                    <b>Қарзга ёзиш</b>
                </p>
                <p onClick={() => onSubmit()} className="card-footer-item">
                    <b>Aрхив</b>
                </p>
                <p onClick={handlePrint} className="card-footer-item"><b>Принт</b></p>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint result={result} item={item} ref={componentRef} />
                </div>
            </footer>

        </div>
    )
}
