import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import queryString from 'querystring'
import { useHistory } from 'react-router-dom'
import { DATE_AUTO_FORMAT } from '../../utils/date'
import Button from './Button'
import { useQueryParams } from '../../hooks/queryString'

export default function Date({
    date,
    onDateChange,
}) {
    function onChange({ target }) {
        onDateChange({
            date: moment(target.name === 'date' ? target.value : date).format(DATE_AUTO_FORMAT),
        })
    }
    const history = useHistory()
    const params = useQueryParams()

    const clearDates = () => {
        history.push(`?${queryString.stringify({
            ...params,
            date: '',
        })}`)
    }
    return (
        <div className={cn('columns is-mobile', css(styles.wrapper))}>
            <div className="column">
                <input
                    name="date"
                    type="date"
                    value={date}
                    className="input"
                    onChange={onChange} />
            </div>

            <div className="column">
                <Button
                    onClick={clearDates}
                    className="button is-danger mr-2 is-outlined"
                    icon="trash" />
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '10rem',
    },
    arrow: {
        // margin: '0.2rem 0.6rem 0.4rem 0',
        fontSize: '1.4em',
    },
})
