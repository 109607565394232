import React, { useState } from 'react'
import { PRODUCT_HISTORY_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import Table from './common/Table'
import ProductHistoryItem from './ProductHistoryItem'

export default function ProductHistory({ productId }) {
    const [page, setPage] = useState(1)
    const products = useLoad({
        url: PRODUCT_HISTORY_LIST,
        params: { product: productId },
    }, [productId])
    const productList = products.response ? products.response.results : []
    //
    return (
        <div className="box">
            <b>
                <div className="is-size-5 mb-3">История покупок товара</div>
            </b>
            <Table
                loading={products.loading}
                items={productList}
                columns={{
                    name: 'Название',
                    count: 'Количество',
                    priceSale: 'Дата покупки',
                }}
                totalCount={products.response ? products.response.count : 0}
                pageSize={15}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item) => (
                    <ProductHistoryItem
                        products={products}
                        key={item.id}
                        item={item}
                    />
                )} />

            <div className="is-size-5 mt-3">Общее количество продаж:
                <span className="has-text-danger ml-2">
                    {
                        products.response ? products.response.total : 0
                    }
                </span>
            </div>
        </div>
    )
}
