import React from 'react'
import { getDateTime } from '../utils/date'

export default function ProductHistoryItem({
    item,
}) {
    return (
        <tr className="is-capitalized" key={item.id}>
            <td className="is-size-5">{item?.product.name}</td>
            <td className="is-size-5">{item.count}</td>
            <td className="is-size-5">{getDateTime(item.purchasedAt)} </td>
        </tr>
    )
}
